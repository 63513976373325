<template>
  <GradientBackLayout class="MembersPageV2"
  :title="'Carga de usuarios'"
  :shadowText="$filters.translate('community')">
    <BoxShadow class="MembersPage--Box">
      <div>
        <div class="info">
          <h2>{{$t('members_load_step1')}}</h2>
          <div>
            <p v-html="$t('members_load_step_1_info')"></p>
            <br/>
            <p v-html="$t('members_load_step_1_info2')"></p>
            <CommunityButton class="demobutton" :color="theme.buttonColor" v-on:click="downloadDemoExcel()">{{$t('members_load_download_demo')}}</CommunityButton>
          </div>
        </div>
        <Separator/>
        <div class="filebox">
          <div class="filebox__title">
            <h2>{{$t('members_load_step2')}}</h2>
          </div>
          <div class="filebox__content">
            <div class="filebox__text">
              <p v-html="$t('members_load_step_2_info')"></p>
              <p v-html="$t('members_load_step_2_info2')"></p>
            </div>
            <div class="fileUploader">
              <Dropzone
                class="LoadUsers__Uploader"
                ref="usersPreviewUploader"
                :uploadUrl="usersPreviewUploader.path"
                :uploadHeaders="usersPreviewUploader.headers"
                :uploadText="$t('members_upload_guide_excel_upload')"
                :maxFilesize="10"
                :maxFiles="1"
                acceptedFiles=".xls,.xlsx"
                v-on:file-uploaded="handlePreviewUploadedFileEvent"
                v-on:file-added="handlePreviewAddedFileEvent"
                v-on:file-removed="handlePreviewRemovedFileEvent"
                v-on:file-error="handlePreviewError"
                :autoUpload="false"
              />
              <!-- acceptedFiles=".xls,.xlsx,.csv" -->
              <h3 class="LoadUsersModal__Error" v-if="!isFileUploaded && fileUploaderError">{{fileUploaderError}}</h3>
              <CommunityButton :color="theme.buttonColor" v-on:click="handlePreviewUpload" :disabled="isFileUploading || !isFileAdded" v-if="!isFileUploaded">{{$t('action_analize')}}</CommunityButton>
            </div>
            <div>
              <p>{{$t('members_load_remove')}}</p>
            </div>

        </div>
        </div>
      </div>
      <div class="results" style="width:100%" v-if="resultsLoaded">
        <Separator/>
        <div class="results__info">
          <div class="results__title">
            <h2>{{$t('members_load_step3')}}</h2>
          </div>
          <div>
            <div>
              {{$t('members_load_step_3_info')}}
            </div>
            <div class="results__messages" v-if="(results.previewResult.messages && results.previewResult.messages.length>0) || (results.previewResult.columnsUnknown && results.previewResult.columnsUnknown.length>0)">
              <h4>{{$t('members_load_advert')}}:</h4>
              <div class="results__messages__box">
                <div class="results__message" v-for="message in results.previewResult.messages">
                  {{getMessageTranslation(message)}}
                </div>
                <div class="results__unknown" v-for="unknownColumn in results.previewResult.columnsUnknown">
                  {{$t('members_load_ignore_column',{unknownColumn:unknownColumn})}}
                </div>
              </div>
            </div>
            <div v-else>
              {{$t('members_load_ready')}}
            </div>
          </div>
        </div>
        <div>
          <div class="results__numbers">
            <div class="results__numbers__total"><i class="fa fa-table"></i>{{$t('members_load_record_total')}}: {{results.previewResult.totalRecords || 0}} / {{ results.usersTotal }}</div>
            <div class="results__numbers__error"><i class="fa fa-times"></i>{{$t('members_load_record_error')}}: {{results.previewResult.errorRecords || 0}}</div>
            <div class="results__numbers__new"><i class="fa fa-user-plus"></i>{{$t('members_load_record_users')}}: {{results.previewResult.newUsers || 0}}</div>
            <div class="results__numbers__update"><i class="fa fa-user"></i>{{$t('members_load_record_update')}}: {{results.previewResult.updatedUsers || 0}}</div>
          </div>
          <table class="results__table">
            <th></th>
            <th></th>
            <th><span><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Email')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Email')"></i>
              {{$t('members_load_email')}}</span></th>
            <th><span>
              <i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Id usuario')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Id usuario')"></i>
              {{$t('members_load_id')}}</span></th>
            <th v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Id Provincia')"><div><i class="fa fa-check"></i></div>
              {{$t('members_load_province')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Zona geográfica')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Zona geográfica')"></i></div>
              {{$t('members_load_geographic')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Edad')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Edad')"></i></div>
              {{$t('members_load_age')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Sexo')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Sexo')"></i></div>
              {{$t('members_load_gender')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Nickname')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Nickname')"></i></div>
              {{$t('members_load_nick')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('User tags')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('User tags')"></i></div>
              {{$t('members_load_tags')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Public tags')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Public tags')"></i></div>
              {{$t('members_load_tags_public')}}</th>
            <th><div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Observaciones')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Observaciones')"></i></div>
              {{$t('members_load_observations')}}</th>
            <th v-if="results.previewResult.columnsDetected.includes('Fecha de nacimiento')">
              <div><i class="fa fa-check" v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Fecha de nacimiento')"></i>
              <i class="fa fa-minus" v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Fecha de nacimiento')"></i></div>
              {{$t('members_load_born')}}</th>
            <th v-for="unknownColumn in results.previewResult.columnsUnknown">
              <i class="fa fa-times"></i>
              {{unknownColumn}}
            </th>
            <tr v-for="(userData, index) in results.previewResult.userRecordDatas">
              <td>{{index+1}}</td>
              <td style="text-align:center"><i class="fa fa-user-plus" v-if="userData.newUser"></i>
                <i class="fa fa-user" v-if="!userData.newUser"></i></td>
              <td>{{userData.email}}</td>
              <td>{{userData.userId}}</td>
              <td v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Id Provincia')">{{userData.provinceId}}</td>
              <td>{{userData.geographicZone}}</td>
              <td>{{userData.age}}</td>
              <td>{{userData.gender}}</td>
              <td v-if="results.previewResult.columnsDetected && results.previewResult.columnsDetected.includes('Nickname')">{{userData.nickname}}</td>
              <td v-if="results.previewResult.columnsDetected && !results.previewResult.columnsDetected.includes('Nickname')">-Auto-</td>
              <td>{{userData.userTags}}</td>
              <td>{{userData.publicTags}}</td>
              <td>{{userData.comments}}</td>
              <td v-if="results.previewResult.columnsDetected.includes('Fecha de nacimiento')">{{userData.bornDate.slice(0, 10)}}</td>
            </tr>
          </table>
        </div>
        <div class="legend">
          <span><i class="fa fa-user-plus"></i> {{$t('members_load_user_to_create')}}</span>
          <span><i class="fa fa-user"></i> {{$t('members_load_user_to_edit')}}</span>
          <span><i class="fa fa-check"></i> {{$t('members_load_column_detected')}}</span>
          <span><i class="fa fa-minus"></i> {{$t('members_load_column_no_detected')}}</span>
          <span><i class="fa fa-times"></i> {{$t('members_load_column_unknown')}}</span>
        </div>
      </div>
      <div class="load"  v-if="resultsLoaded">
        <Separator/>
        <div class="load__content">
          <div class="load__title">
            <h2>{{$t('members_load_step4')}}</h2>
          </div>
          <div class="load__box">
            <div class="load__text">
              <span style="margin-right:20px" v-show="results.previewResult.errorRecords>0">{{$t('members_load_step_4_info')}}</span>
              <span style="margin-right:20px" v-show="results.previewResult.errorRecords==0" v-html="$t('members_load_step_4_info2')"></span>
            </div>
            <div class="results__actions">
              <CommunityButton :color="theme.buttonColor" v-on:click="handleUsersUpload" v-show="results.previewResult.errorRecords==0" v-if="isFileUploaded">{{$t('members_load_proceed')}}</CommunityButton>
              <CommunityButton :color="theme.buttonColor" v-on:click="handleUsersUpload" v-show="results.previewResult.errorRecords>0" v-if="isFileUploaded">{{$t('members_load_with_errors')}}</CommunityButton>
            </div>
          </div>
        </div>
      </div>
      <div class="LoadingDataModal" v-if="showModal">
        <div class="LoadingDataModal--Modal">
          <h1>{{ $t('members_upload_guide_modal_title') }}</h1>
          <div class="LoadingDataModal--Progress" v-if="isLoadingPreview">
            <p>Usuarios extraidos del excel <b>({{ results.previewExtractedProcessed }}/{{ results.usersTotal }})</b></p>
            <div style="height:10px">
              <ProgressBar :value="results.previewExtractedCompletionPercent"/>
            </div>
          </div>
          <div class="LoadingDataModal--Progress" v-if="isLoadingPreviewed">
            <p>Cargando usuarios <b>({{ results.loadCreatedProcessed }}/{{ results.usersTotal }})</b></p>
            <div style="height:10px">
              <ProgressBar :value="results.loadCreatedCompletionPercent"/>
            </div>
          </div>
          <div class="LoadingDataModal--Progress" v-if="isLoadingPreviewed">
            <p>Añadiendo usuarios a la comunidad <b>({{ results.loadAddedProcessed }}/{{ results.usersTotal }})</b></p>
            <div style="height:10px">
              <ProgressBar :value="results.loadAddedCompletionPercent"/>
            </div>
          </div>
          <div class="LoadingDataModal--Progress" v-if="isLoadingPreviewed">
            <p>Procesando usuarios <b>({{ results.loadNotifiedProcessed }}/{{ results.usersTotal }})</b></p>
            <div style="height:10px">
              <ProgressBar :value="results.loadNotifiedCompletionPercent"/>
            </div>
          </div>
          <span>
            No cierres ni recargues la página hasta terminar el proceso o dejarás de ver el progreso.
          </span>
        </div>
      </div>


    </BoxShadow>
  </GradientBackLayout>
</template>
<script>
import ProgressBar from '@/components/ProgressBar.vue'
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import store from '@/store';
import { COMMUNITY_FETCH_DEMO_EXCEL, COMMUNITY_LOAD_PREVIEWED_USERS_EXCEL, COMMUNITY_FETCH_MEMBERS_JOB_STATUS, COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN, COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN_MX } from '@/store/community/members.module';
import GradientBackLayout from '@/layouts/GradientBackLayout'
import BoxShadow from '@/components/BoxShadow.vue'
import CommunityButton from '@/components/CommunityButton';
import Dropzone from '~/components/Dropzone';
import ApiService from '@/services/api.service';
import FileSaver from 'file-saver';
import Separator from '@/components/Separator.vue';
export default {
  name: 'MembersLoadPage',
  components: {
    GradientBackLayout,
    BoxShadow,
    CommunityButton,
    Dropzone,
    Separator,
    ProgressBar
  },
  computed: {
    ...mapGetters([
      'communityId',
      'theme',
      'isCommunityMainAdmin',
      'isModerator',
      'country'
    ]),
    usersPreviewUploader() {
      return {
        path: process.env.VUE_APP_API_URL + '/experienceLabs/community/' + this.communityId + '/previewUsersExcel',
        headers: ApiService.getConfig().headers,
      };
    },
    usersUploader() {
      return {
        path: process.env.VUE_APP_API_URL + '/experienceLabs/community/' + this.communityId + '/usersExcel',
        headers: ApiService.getConfig().headers,
      };
    },
  },
  data: () => ({
    isFileUploading: false,
    isFileUploaded: false,
    isFileAdded: false,
    fileUploaderError:'',
    resultsLoaded: false,
    resultsLoading: false,
    savingUsers: false,
    isLoadingPreview: false,
    isLoadingPreviewed: false,
    showModal: false,
    results: {},
    fileName: '',
  }),
  methods: {
    closeModal(){
      if(this.isLoadingPreviewed) {
        Swal.fire({
          title:this.$t('msg_load_users_correctly'),
          icon:"success",
          customClass:{popup:"swal-customSuccess"},
        })
      }
      this.showModal = false
      this.isLoadingPreview = false
      this.isLoadingPreviewed = false
      this.isFileUploaded = true;
      this.isFileAdded = false;
    },
    async fetchJobStatus(){
      await store.dispatch(COMMUNITY_FETCH_MEMBERS_JOB_STATUS,{jobId:this.results.identifier})
      .then((data) => {
        this.results = data.data
        if(data.data.status != 'PREVIEWED' && data.data.status != 'ERROR' && data.data.status != 'LOADED') setTimeout(this.fetchJobStatus, 2000);
        else setTimeout(this.closeModal, 2000);
      })
    },
    showModalAlreadyLoading(){
      Swal.fire({
          title:this.$t('msg_load_users_already_uploading'),
          icon:"error",
          customClass:{popup:"swal-customError"},
        })
    },
    handleUsersUpload() {
      store.dispatch(COMMUNITY_LOAD_PREVIEWED_USERS_EXCEL, {
        previewedFileUrl: this.results.previewResult.fileUrl,
        jobId:this.results.identifier
      }).then((data) => {
        this.isLoadingPreviewed = true
        this.showModal = true
        $('.GradientBack').animate({scrollTop: 0},600);
        setTimeout(this.fetchJobStatus, 2000);
      }).catch(({response}) => {
        if(response.status == 400){
          this.showModalAlreadyLoading()
        }
        else{
          Swal.fire({
            title:this.$t('msg_load_users_failed'),
            icon:"error",
            customClass:{popup:"swal-customError"},
          })
        }
      })
    },
    handlePreviewUpload() {
      this.isLoadingPreview = true
      this.showModal = true
      this.resultsLoading = true;
      this.fileUploaderError = "";
      this.isFileUploading = true;
      this.$refs.usersPreviewUploader.processUpload();
    },
    handlePreviewError(file, message, xhr) {
      this.isFileUploading = false;
      this.fileUploaderError = message.payload;
    },
    handlePreviewAddedFileEvent() {
      this.isFileAdded = true;
    },
    handlePreviewRemovedFileEvent() {
      this.isFileAdded = false;
      this.isFileUploading = false;
      this.isFileUploaded = false;
      this.results = {};
      this.resultsLoaded = false;
    },
    async handlePreviewUploadedFileEvent(file,response) {
      this.results = {};
      this.results = response;
      this.resultsLoaded = true;
      this.resultsLoading = false;
      this.isFileUploading = false;
      this.fileName = file.name;
      setTimeout(this.fetchJobStatus, 2000);
    },
    downloadDemoExcel: async function(){
      let serviceName = this.isCommunityMainAdmin ? COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN : COMMUNITY_FETCH_DEMO_EXCEL
      if(this.country.toLowerCase() == 'mx') serviceName = COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN_MX
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation'),
        text: vm.$t('msg_file_download'),
        icon: 'warning',
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('action_accept'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            await store.dispatch(serviceName,{})
            .then((data) => {
              var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              FileSaver.saveAs(file,vm.$t('members_demo_file')+".xlsx");
            })
          })();
        }
      })
    },
    getMessageTranslation(message) {
      if (message.includes("Email not valid in the excel row:")) {
        const parts = message.split(":");
        const key = parts[0].trim();
        const value = parts[1].trim();
        if (this.$t('members_load_error_messages.' + key)) {
          return this.$t('members_load_error_messages.' + key + ":") + value;
        }
      } else if (message.includes("Age can not be negative in the excel row:")) {
        const parts = message.split(":");
        const key = parts[0].trim();
        const value = parts[1].trim();
        if (this.$t('members_load_error_messages.' + key)) {
          return this.$t('members_load_error_messages.' + key + ":") + value;
        }
      }
      return this.$t('members_load_error_messages.' + message);
    }
  }
}
</script>
<style scoped lang="scss">
.LoadingDataModal{
  position: fixed;
  background: rgba(0,0,0,0.5);
  top: 0px;
  left: 0px;
  width: 100% ;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &--Modal{
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border-radius: 5px;
    background: #fff;
    position: relative;
    overflow: hidden;
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
  span{
    padding: 15px;
    color: #0c5460;
    background-color: #d1ecf1;
    border:1px solid #bee5eb;

  }
}
.MembersPage--Box{
  flex-wrap: wrap;
  padding:50px;
}
.demobutton{
  margin: 15px;

}
.info{
  display: flex;
}
.filebox{
  display: flex;
  //flex-wrap: wrap;
  button{
    margin-left:20px;
  }
  &__title{
    min-width:40%;
  }
  &__content{
    //display:flex;
  }
}
.fileUploader{
  margin-top:20px;
  margin-bottom:20px;
  min-width: 35%;
}
.LoadUsers__Uploader{
  margin-bottom:10px;
}
.results{
  margin-top:40px;
  padding-top: 20px;
  min-height: 200px;
  &__info{
    display: flex;
  }
  &__title{
    margin-bottom:15px;
    min-width: 40%;
  }
  &__table{
    width:100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid grey;
    th{
      border-bottom: 1px solid grey;
      margin:5px;
      background-color: #e5e5e5;
    }
    th, td {
      padding: 5px;
    }
  }
  &__messages{
    padding-top:20px;
    font-size: 18px;
    margin-bottom:20px;
    &__box{
      margin-top:10px;
      background-color: #e5e5e5;
      padding:20px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
      overflow-y: auto;
      max-height: 150px;
    }
  }
  &__message{
    color: darkred;
  }
  &__numbers{
    width:100%;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    font-weight: bold;
    display: flex;
    justify-content: space-evenly;
    i{
      margin-right: 5px;
    }
    div{
      margin-right: 30px;
    }
    &__total{
      //color: royalblue;
    }
    &__error{
      //color: darkred;
    }
    &__new{
      //color: green;
    }
    &__update{
      //color: royalblue;
    }
  }
  &__actions{
    margin-top: 20px;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    button{
      margin-left:10px;
    }
  }
}
.load{
  width:100%;
  &__title{
    min-width: 40%;
  }
  &__content{
    display: flex;
  }
}
.fa{
  font-size: 25px;
}
.fa-check{
  color: green;
}
.fa-minus{
  color: grey;
}
.fa-times{
  color: darkred;
}
.spinner_box{
    text-align: center;
    margin-top:30px;
    width:100%;
}
.fa-spinner{
    animation: rotate 1s infinite;
    font-size: 70px;
    @keyframes rotate {
      from {transform: rotate(0deg);color:lightgray;}
      to {transform: rotate(360deg);color:var(--primary-color);}
    }
}
.legend{
  margin-left: 20px;
  margin-bottom: 20px;
  font-size:0.8em;
  span{
    margin-right:20px;
  }
}
h2{
  font-size: 35px;
  font-weight: 700;
  min-width: 40%
}
</style>
